/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// components

import IndexDropdown from "components/Dropdowns/IndexDropdown.js";

export default function Navbar(props) {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    return (
        <>
            <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
                <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                    <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                        <Link
                            to="/"
                            className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
                        >
                            <img
                                src={require("assets/X3/X3 LOGOTIPO-COR-02.png").default}
                                style={{ maxHeight: 50, maxWidth: 50 }}
                                alt="X3 logotipo"
                            />
                        </Link>
                        <button
                            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                            type="button"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                        >
                            <i className="fas fa-bars"></i>
                        </button>
                    </div>
                    <div
                        className={
                            "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
                            (navbarOpen ? " block" : " hidden")
                        }
                        id="example-navbar-warning"
                    >
                        {/*<ul className="flex flex-col lg:flex-row list-none mr-auto">*/}
                        {/*    <li className="flex items-center">*/}
                        {/*        <a*/}
                        {/*            className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"*/}
                        {/*            href="https://www.creative-tim.com/learning-lab/tailwind/react/overview/notus?ref=nr-index-navbar"*/}
                        {/*        >*/}
                        {/*            <i className="text-blueGray-400 far fa-file-alt text-lg leading-lg mr-2" />{" "}*/}
                        {/*            Docs*/}
                        {/*        </a>*/}
                        {/*    </li>*/}
                        {/*</ul>*/}
                        <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                            <li className="flex items-center">
                                <IndexDropdown />
                            </li>
                            <li className="flex items-center">
                                <a
                                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                                    href="https://www.facebook.com/profile.php?id=61555837405202"
                                    target="_blank"
                                >
                                    <i className="text-blueGray-400 fab fa-facebook text-lg leading-lg " />
                                    <span className="lg:hidden inline-block ml-2">Facebook</span>
                                </a>
                            </li>

                            <li className="flex items-center">
                                <a
                                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                                    href="https://www.instagram.com/x3it_software/"
                                    target="_blank"
                                >
                                    <i className="text-blueGray-400 fab fa-instagram text-lg leading-lg " />
                                    <span className="lg:hidden inline-block ml-2">Instagram</span>
                                </a>
                            </li>

                            <li className="flex items-center">
                                <a
                                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                                    href="https://www.linkedin.com/company/x3it/"
                                    target="_blank"
                                >
                                    <i className="text-blueGray-400 fab fa-linkedin text-lg leading-lg " />
                                    <span className="lg:hidden inline-block ml-2">LinkedIn</span>
                                </a>
                            </li>

                            {/*<li className="flex items-center">*/}
                            {/*    <button*/}
                            {/*        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"*/}
                            {/*        type="button"*/}
                            {/*    >*/}
                            {/*        <i className="fas fa-arrow-alt-circle-down"></i> Download*/}
                            {/*    </button>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}
