/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

const BLUECOLOR = "#01249C"


export default function Index() {

    const contact = (e) => {
        window.open('mailto:geral@x3it.pt')
    }


    return (
        <>
            <IndexNavbar fixed />
            <section className="header relative pt-16 items-center flex h-screen max-h-860-px">


                <div className="container mx-auto items-center flex flex-wrap">
                    <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
                        <div className="pt-32 sm:pt-0">
                            <h2 className="font-semibold text-4xl text-blueGray-600">
                                X3IT - Inovando para o futuro, construindo o presente.
                            </h2>
                            <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                                A nossa missão é fornecer soluções de software que impulsionem o sucesso do seu negócio. Entre em contato conosco hoje mesmo para descobrir como podemos ajudar a alcançar os seus objetivos.
                            </p>
                            <div className="mt-12">
                                <a
                                    target="_blank"
                                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                    style={{ backgroundColor: BLUECOLOR }}
                                    onClick={contact}
                                >
                                    Entrar em contacto
                                </a>

                            </div>
                        </div>
                    </div>




                    <div className="w-full md:w-4/12 lg:w-6/12 xl:w-6/12 px-4">
                        <img
                            src={require("assets/X3/X3 LOGOTIPO-COR-02.png").default}
                            alt="..." className="max-w-full h-auto align-middle border-none" />
                    </div>
                </div>



            </section>

            <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
                <div
                    className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
                    style={{ transform: "translateZ(0)" }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-blueGray-100 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>


                <div className="container mx-auto">


                    <div className="flex flex-wrap items-center">


                        {/*

                        <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                                <img
                                    alt="..."
                                    src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80"
                                    className="w-full align-middle rounded-t-lg"
                                />
                                <blockquote className="relative p-8 mb-4">
                                    <svg
                                        preserveAspectRatio="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 583 95"
                                        className="absolute left-0 w-full block h-95-px -top-94-px"
                                    >
                                        <polygon
                                            points="-30,95 583,95 583,65"
                                            className="text-lightBlue-500 fill-current"
                                        ></polygon>
                                    </svg>
                                    <h4 className="text-xl font-bold text-white">
                                        Great for your awesome project
                                    </h4>
                                    <p className="text-md font-light mt-2 text-white">
                                        Putting together a page has never been easier than matching
                                        together pre-made components. From landing pages
                                        presentation to login areas, you can easily customise and
                                        built your pages.
                                    </p>
                                </blockquote>
                            </div>
                        </div>

                        <div className="w-full md:w-6/12 px-4">
                            <div className="flex flex-wrap">
                                <div className="w-full md:w-6/12 px-4">
                                    <div className="relative flex flex-col mt-4">
                                        <div className="px-4 py-5 flex-auto">
                                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                                <i className="fas fa-sitemap"></i>
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">
                                                CSS Components
                                            </h6>
                                            <p className="mb-4 text-blueGray-500">
                                                Notus React comes with a huge number of Fully Coded CSS
                                                components.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="relative flex flex-col min-w-0">
                                        <div className="px-4 py-5 flex-auto">
                                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                                <i className="fas fa-drafting-compass"></i>
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">
                                                JavaScript Components
                                            </h6>
                                            <p className="mb-4 text-blueGray-500">
                                                We also feature many dynamic components for React,
                                                NextJS, Vue and Angular.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-6/12 px-4">
                                    <div className="relative flex flex-col min-w-0 mt-4">
                                        <div className="px-4 py-5 flex-auto">
                                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                                <i className="fas fa-newspaper"></i>
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">Pages</h6>
                                            <p className="mb-4 text-blueGray-500">
                                                This extension also comes with 3 sample pages. They are
                                                fully coded so you can start working instantly.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="relative flex flex-col min-w-0">
                                        <div className="px-4 py-5 flex-auto">
                                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                                <i className="fas fa-file-alt"></i>
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">
                                                Documentation
                                            </h6>
                                            <p className="mb-4 text-blueGray-500">
                                                Built by developers for developers. You will love how
                                                easy is to to work with Notus React.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        */ }

                    </div>
                </div>

                <div className="container mx-auto overflow-hidden pb-20">
                    <div className="flex flex-wrap items-center">
                        <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                                <i className="fas fa-window-restore text-xl"></i>
                            </div>
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                Produtos
                            </h3>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                
                            </p>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                • Sotware "á medida" das necessidades do cliente
                            </p>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                • Gestor RH
                            </p>


                            {/*<a*/}
                            {/*    href="https://www.creative-tim.com/learning-lab/tailwind/react/alerts/notus?ref=nr-index"*/}
                            {/*    target="_blank"*/}
                            {/*    className="font-bold text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150"*/}
                            {/*>*/}
                            {/*    View All{" "}*/}
                            {/*    <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>*/}
                            {/*</a>*/}
                        </div>

                        <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
                            <img
                                alt="..."
                                className="max-w-full"
                                //style={{transform:"scale(1) perspective(1040px) rotateY(-11deg) rotateX(3deg) rotate(3deg)",}}
                                style={{transform:"scale(1.2)",}}
                                src={require("assets/landPage/product.png").default}
                            />
                        </div>


                        {/*<div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">*/}
                        {/*    <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">*/}
                        {/*        <img*/}
                        {/*            alt="..."*/}
                        {/*            src={require("assets/img/component-btn.png").default}*/}
                        {/*            className="w-full align-middle rounded absolute shadow-lg max-w-100-px z-3 left-145-px -top-29-px"*/}
                        {/*        />*/}
                        {/*        <img*/}
                        {/*            alt="..."*/}
                        {/*            src={require("assets/img/component-profile-card.png").default}*/}
                        {/*            className="w-full align-middle rounded-lg absolute shadow-lg -top-160-px left-260-px max-w-210-px"*/}
                        {/*        />*/}
                        {/*        <img*/}
                        {/*            alt="..."*/}
                        {/*            src={require("assets/img/component-info-card.png").default}*/}
                        {/*            className="w-full align-middle rounded-lg absolute shadow-lg max-w-180-px -top-225-px left-40-px z-2"*/}
                        {/*        />*/}
                        {/*        <img*/}
                        {/*            alt="..."*/}
                        {/*            src={require("assets/img/component-info-2.png").default}*/}
                        {/*            className="w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-50-px top-25-px"*/}
                        {/*        />*/}
                        {/*        <img*/}
                        {/*            alt="..."*/}
                        {/*            src={require("assets/img/component-menu.png").default}*/}
                        {/*            className="w-full align-middle rounded absolute shadow-lg max-w-580-px -left-20-px top-210-px"*/}
                        {/*        />*/}
                        {/*        <img*/}
                        {/*            alt="..."*/}
                        {/*            src={require("assets/img/component-btn-pink.png").default}*/}
                        {/*            className="w-full align-middle rounded absolute shadow-xl max-w-120-px left-195-px top-95-px"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                    <div className="flex flex-wrap items-center pt-32">
                        <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
                            <div className="justify-center flex flex-wrap relative">
                                <div className="my-4 w-full lg:w-6/12 px-4">
                                    <a
                                        target="_blank"
                                    >
                                        <div className="bg-red-600 shadow-lg rounded-lg text-center p-8">
                                            <img
                                                alt="..."
                                                className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                                                src={require("assets/dev/dotnet.png").default}
                                            />
                                            <p className="text-lg text-white mt-4 font-semibold">
                                                ASP .NET
                                            </p>
                                        </div>
                                    </a>
                                    <a
                                        target="_blank"
                                    >
                                        <div className="bg-lightBlue-500 shadow-lg rounded-lg text-center p-8 mt-8">
                                            <img
                                                alt="..."
                                                className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                                                src={require("assets/dev/react.jpg").default}
                                            />
                                            <p className="text-lg text-white mt-4 font-semibold">
                                                ReactJS
                                            </p>
                                        </div>
                                    </a>
                                    <a
                                        target="_blank"
                                    >
                                        <div className="bg-blueGray-700 shadow-lg rounded-lg text-center p-8 mt-8">
                                            <img
                                                alt="..."
                                                className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                                                src={require("assets/dev/nextjs.jpg").default}
                                            />
                                            <p className="text-lg text-white mt-4 font-semibold">
                                                NextJS
                                            </p>
                                        </div>
                                    </a>
                                </div>
                                <div className="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
                                    <a

                                        target="_blank"
                                    >
                                        <div className="bg-yellow-500 shadow-lg rounded-lg text-center p-8">
                                            <img
                                                alt="..."
                                                className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                                                src={require("assets/dev/js.png").default}
                                            />
                                            <p className="text-lg text-white mt-4 font-semibold">
                                                JavaScript
                                            </p>
                                        </div>
                                    </a>
                                    <a

                                        target="_blank"
                                    >
                                        <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-8 mt-8">
                                            <img
                                                alt="..."
                                                className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                                                src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/vue.jpg"
                                            />
                                            <p className="text-lg text-white mt-4 font-semibold">
                                                Vue.js
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                                <i className="fas fa-drafting-compass text-xl"></i>
                            </div>
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                Estratégia de desenvolvimento
                            </h3>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                Descubra as tecnologias de ponta que utilizamos:
                            </p>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                • ReactJS e React Native para interfaces dinâmicas e aplicações móveis nativas.
                            </p>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                • ASP.NET Core para aplicações web escaláveis e seguras.
                            </p>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                • Angular e Vue.js para interfaces web modernas e responsivas.
                            </p>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                • Next.js para renderização eficiente do lado do servidor e experiências otimizadas para o utilizador.
                            </p>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">

                            </p>
                            {/*<div className="block pb-6">*/}
                            {/*    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">*/}
                            {/*        Responsive*/}
                            {/*    </span>*/}
                            {/*    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">*/}
                            {/*        C#*/}
                            {/*    </span>*/}
                            {/*    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">*/}
                            {/*        JavaScript*/}
                            {/*    </span>*/}
                            {/*    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">*/}
                            {/*        Razor*/}
                            {/*    </span>*/}
                            {/*    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">*/}
                            {/*        React Native*/}
                            {/*    </span>*/}
                            {/*</div>*/}
                            {/*<a*/}
                            {/*    href="https://www.creative-tim.com/learning-lab/tailwind/react/alerts/notus?ref=nr-index"*/}
                            {/*    target="_blank"*/}
                            {/*    className="font-bold text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150"*/}
                            {/*>*/}
                            {/*    View all{" "}*/}
                            {/*    <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>*/}
                            {/*</a>*/}
                        </div>
                    </div>
                </div>



                <div className="container mx-auto px-4 pb-32 pt-48">
                    <div className="items-center flex flex-wrap">
                        <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
                            <div className="md:pr-12">
                                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                                    <i className="fas fa-building text-xl"></i>
                                </div>
                                <h3 className="text-3xl font-semibold">
                                    A X3
                                </h3>
                                <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                                    Empresa que nasce em 2023, com o objetivo de dinamizar o software desenvolvido para a industria.
                                </p>
                                <ul className="list-none mt-6">
                                    <li className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                                                    {/*<i className="fas fa-fingerprint"></i>*/}
                                                    <i className="fas fa-chart-line"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Proporcionar aos nossos clientes os mais altos padrões de qualidade e serviços.
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                                                    <i className="fab fa-html5"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Liderar o setor através da inovação e excelência.
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                                                    {/*<i className="far fa-paper-plane"></i>*/}
                                                    <i className="fas fa-industry"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Compromisso com a excelência, integridade e responsabilidade social.
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
                            <img
                                alt="..."
                                className="max-w-full rounded-lg shadow-xl"
                                style={{
                                    transform:
                                        "scale(1) perspective(1040px) rotateY(-11deg) rotateX(3deg) rotate(3deg)",
                                }}
                                src={require("assets/X3/X3 LOGOTIPO-COR-02.png").default}
                            />
                        </div>
                    </div>
                </div>


                {/*
                <div className="justify-center text-center flex flex-wrap mt-24">
                    <div className="w-full md:w-6/12 px-12 md:px-4">
                        <h2 className="font-semibold text-4xl">Beautiful Example Pages</h2>
                        <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                            Notus React is a completly new product built using our past
                            experience in web templates. Take the examples we made for you and
                            start playing with them.
                        </p>
                    </div>
                </div>
                */ }
            </section>




            {/*
            <section className="block relative z-1 bg-blueGray-600">
                <div className="container mx-auto">
                    <div className="justify-center flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4  -mt-24">
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-4/12 px-4">
                                    <h5 className="text-xl font-semibold pb-4 text-center">
                                        Login Page
                                    </h5>
                                    <Link to="/auth/login">
                                        <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                            <img
                                                alt="..."
                                                className="align-middle border-none max-w-full h-auto rounded-lg"
                                                src={require("assets/img/login.jpg").default}
                                            />
                                        </div>
                                    </Link>
                                </div>

                                <div className="w-full lg:w-4/12 px-4">
                                    <h5 className="text-xl font-semibold pb-4 text-center">
                                        Profile Page
                                    </h5>
                                    <Link to="/profile">
                                        <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                            <img
                                                alt="..."
                                                className="align-middle border-none max-w-full h-auto rounded-lg"
                                                src={require("assets/img/profile.jpg").default}
                                            />
                                        </div>
                                    </Link>
                                </div>

                                <div className="w-full lg:w-4/12 px-4">
                                    <h5 className="text-xl font-semibold pb-4 text-center">
                                        Landing Page
                                    </h5>
                                    <Link to="/landing">
                                        <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                            <img
                                                alt="..."
                                                className="align-middle border-none max-w-full h-auto rounded-lg"
                                                src={require("assets/img/landing.jpg").default}
                                            />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            */ }

            {/*
                <section className="py-20 bg-blueGray-600 overflow-hidden">
                    <div className="container mx-auto pb-64">
                        <div className="flex flex-wrap justify-center">
                            <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
                                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                                    <i className="fas fa-code-branch text-xl"></i>
                                </div>
                                <h3 className="text-3xl mb-2 font-semibold leading-normal text-white">
                                    Open Source
                                </h3>
                                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-400">
                                    Since{" "}
                                    <a
                                        href="https://tailwindcss.com/?ref=creativetim"
                                        className="text-blueGray-300"
                                        target="_blank"
                                    >
                                        Tailwind CSS
                                    </a>{" "}
                                    is an open source project we wanted to continue this movement
                                    too. You can give this version a try to feel the design and also
                                    test the quality of the code!
                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-400">
                                    Get it free on Github and please help us spread the news with a
                                    Star!
                                </p>
                                <a
                                    href="https://github.com/creativetimofficial/notus-react?ref=nr-index"
                                    target="_blank"
                                    className="github-star mt-4 inline-block text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg"
                                >
                                    Github Star
                                </a>
                            </div>

                            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto mt-32 relative">
                                <i className="fab fa-github text-blueGray-700 absolute -top-150-px -right-100 left-auto opacity-80 text-55"></i>

                            </div>
                        </div>
                    </div>
                </section>
           */ }

            <section className="pt-20 pb-48">
                <div
                    className="container mx-auto px-4"
                    style={{ alignContent: "center" }}
                >
                    <div className="flex flex-wrap justify-center text-center mb-24">
                        <div className="w-full lg:w-6/12 px-4">
                            <h2 className="text-4xl font-semibold">Os que contam conosco</h2>
                            <p className="text-lg leading-relaxed m-4 text-blueGray-500">

                            </p>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-center">
                        <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                            <div className="px-6">
                                <img
                                    alt="..."
                                    src={require("assets/Clientes/logoLeirilis1.png").default}
                                    className="mx-auto max-w-120-px"
                                />
                                <div className="pt-6 text-center">
                                    <h5 className="text-xl font-bold">Leirilis</h5>

                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                                <div className="px-6">
                                    <img

                                        alt="..."
                                        src={require("assets/Clientes/logoRW.png").default}
                                        className="mx-auto max-w-120-px"
                                    />
                                    <div className="pt-6 text-center">
                                        <h5 className="text-xl font-bold">RedWaste</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                                <div className="px-6">
                                    <img

                                        alt="..."
                                        src={require("assets/Clientes/logoVaridauto.png").default}
                                        className="mx-auto max-w-120-px"
                                    />
                                    <div className="pt-6 text-center">
                                        <h5 className="text-xl font-bold">Varidauto</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                                <div className="px-6">
                                    <img

                                        alt="..."
                                        src={require("assets/Clientes/logoIG.png").default}
                                        className="mx-auto max-w-120-px"
                                    />
                                    <div className="pt-6 text-center">
                                        <h5 className="text-xl font-bold">Irmãos Gomes</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                                <div className="px-6">
                                    <img

                                        alt="..."
                                        src={require("assets/Clientes/logo.biapecas.png").default}
                                        className="mx-auto max-w-120-px"
                                    />
                                    <div className="pt-6 text-center">
                                        <h5 className="text-xl font-bold">Biapeças</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="pb-16 bg-blueGray-200 relative pt-32">
                <div
                    className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
                    style={{ transform: "translateZ(0)" }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-blueGray-200 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>

                <div className="container mx-auto">
                    <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
                        <div className="w-full text-center lg:w-8/12">
                            <h3 className="font-semibold text-3xl">
                                Quer pretencer á nossa carteira de clientes?
                            </h3>
                            <p className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                                Contacte nos para saber todas as informações necessárias para inciarmos um projeto!
                            </p>
                            <div className="sm:block flex flex-col mt-10">
                                <a
                                    onClick={contact}
                                    style={{ backgroundColor: BLUECOLOR }}
                                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                >
                                    Entrar em contacto
                                </a>

                            </div>
                            <div className="text-center mt-16"></div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
